/*
 * @Author: wangping wangping@gdtwzc.com
 * @Date: 2023-08-28 14:07:53
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-12-20 09:40:55
 * @FilePath: \twzc005-1\src\minix\webscoket.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default{
    data(){
        return{
            io:''
        }
    },
    methods:{
        webscoket(){
            let that=this
            // 创建WebSocket连接
            const socket = new WebSocket('ws://127.0.0.1:8866');

            // 当连接建立时触发
            socket.onopen = function() {
            console.log('WebSocket连接已建立');
            
            // 发送消息到服务器
            socket.send('Hello Server!');
            };

            // 当接收到来自服务器的消息时触发
            socket.onmessage = function(event) {
            // console.log('收到来自服务器的消息1:', event.data);
           
            if(event.data=='{"sensor": "CAM", "action": "GESTURE", "val": "Bye", "extras": ""}' && that.io!='{"sensor": "CAM", "action": "GESTURE", "val": "Bye", "extras": ""}'){
              console.log('收到来自服务器的消息1:', event.data);
                that.io=event.data
            }else if(event.data=='{"sensor": "CAM", "action": "GESTURE", "val": "0", "extras": ""}' && that.io!='{"sensor": "CAM", "action": "GESTURE", "val": "0", "extras": ""}'){
                console.log('收到来自服务器的消息1:', event.data);
                that.io=event.data
            }
            };

            // 当连接关闭时触发
            socket.onclose = function(event) {
            console.log('WebSocket连接已关闭:', event.code, event.reason);
            };

            // 当连接发生错误时触发
            socket.onerror = function(error) {
            console.error('WebSocket连接错误:', error);
            };
        }
    }
}