/*
 * @Author: wangping wangping@gdtwzc.com
 * @Date: 2023-10-14 15:03:17
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-12-20 09:47:38
 * @FilePath: \ybj_szr\src\minix\voiceToText.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import Recorder from "recorder-core";
//引入mp3格式支持文件；如果需要多个格式支持，把这些格式的编码引擎js文件放到后面统统引入进来即可
import "recorder-core/src/engine/mp3";
import "recorder-core/src/engine/mp3-engine";
//录制wav格式的用这一句就行
//import 'recorder-core/src/engine/wav'

//可选的插件支持项，这个是波形可视化插件
import "recorder-core/src/extensions/waveview";
import "@/assets/js/hmac_md5.js";
import "@/assets/js/xfAsr.js";
var rec = null;
var asr = null;
export default {
  data() {
    return {
      speechInputTimeout:null
    };
  },
  methods: {
    recOpen() {
      return new Promise((resolve, reject) => {
        //创建录音对象
        var rec2 = Recorder({
          type: "mp3", //录音格式，可以换成wav等其他格式
          sampleRate: 16000, //录音的采样率，越大细节越丰富越细腻
          bitRate: 16, //录音的比特率，越大音质越好
          onProcess: (
            buffers,
            powerLevel,
            bufferDuration,
            bufferSampleRate,
            newBufferIdx,
            asyncEnd
          ) => {
            //录音实时回调，大约1秒调用12次本回调
            //可实时绘制波形，实时上传（发送）数据
            if (asr) {
              asr.chunk = Recorder.SampleData(
                buffers,
                bufferSampleRate,
                16000,
                asr.chunk
              );
              asr.input(asr.chunk.data);
              // if(this.$refs.recwave){
              //   this.wave.input(
              //       buffers[buffers.length - 1],
              //       powerLevel,
              //       bufferSampleRate
              //     );
              // }
            }
          },
        });

        //打开录音，获得权限
        rec2.open(
          (stream) => {
            rec = rec2;
            console.log(stream, "99999999999");
            this.recording = true;
            // if (this.$refs.recwave) {
            //   //创建音频可视化图形绘制对象
            //   this.wave = Recorder.WaveView({
            //     elem: this.$refs.recwave,
            //   });
            // }
            resolve({
              code: 200,
            }); // 成功时，将流传递给 Promise
          },
          (msg, isUserNotAllow) => {
            console.log(msg, isUserNotAllow, "6666666666666");
            //用户拒绝了录音权限，或者浏览器不支持录音
            console.info(
              (isUserNotAllow ? "UserNotAllow，" : "") + "无法录音:" + msg
            );
            alert(
              (isUserNotAllow ? "UserNotAllow，" : "") + "录音打开失败：" + msg
            );
            reject({
              code: 0,
            }); // 失败时，将错误信息传递给 Promise
          }
        );
      });
    },
    recStart() {
      this.content = "";
      if (!rec) {
        console.error("未打开录音");
        return;
      }
      if (asr) asr.stop();
      rec.start();
      const AppID = "7734097b";
      const APIKey = "78d0ca7b22f6dc8131f4b30765e0048b";
      const date = "" + ~~(Date.now() / 1000);
      const str = MD5(AppID + date);
      // this.asrStart()
      HMAC_SHA1(
        APIKey,
        str,
        (signature) => {
          const data = {
            appID: AppID,
            host: "rtasr.xfyun.cn",
            path: "/v1/ws",
            date: date,
            signa: signature,
          };
          this.asrStart(data);
        },
        (err) => {
          this.recStop();
          alert("打开语音识别出错：" + err);
        }
      );
      console.log("已开始录音", rec);
    },
    asrStart(authData) {
      asr = new XfAsr({
        authData: authData,
        onError: (err) => {
          console.log(err, "识别出错了处理");
          // 识别出错了处理
        },
        onText: (text) => {
          console.log(text, "实时识别到的文本");
          // 实时识别到的文本
          this.content = text;
          clearTimeout(this.speechInputTimeout);
          this.speechInputTimeout = null;
          this.speechInputTimeout = setTimeout(() => {
            if (this.content.length > 5) {
              this.confirmBtn();
            }
          }, 5000);
        },
      });
      asr.start(
        () => {
          // 开始了就行
        },
        (err) => {
          asr = null;
          this.recStop();
          alert("打开语音识别出错：" + err);
        }
      );
    },
    recStop() {
      if (!rec) {
        console.error("未打开录音");
        return;
      }
      rec.stop();
      if (!asr) return;
      var asr2 = asr;
      asr = null;
      asr2.stop(
        (text) => {
          this.historyContent += text;
          this.content = "";
          // this.content = text;
        },
        (err) => {
          alert("语音识别错误：" + err);
        }
      );
      // this.rec.stop(
      //   (blob, duration) => {
      //     //blob就是我们要的录音文件对象，可以上传，或者本地播放
      //     this.recBlob = blob;
      //     //简单利用URL生成本地文件地址，此地址只能本地使用，比如赋值给audio.src进行播放，赋值给a.href然后a.click()进行下载（a需提供download="xxx.mp3"属性）
      //     var localUrl = (window.URL || webkitURL).createObjectURL(blob);
      //     console.log("录音成功", blob, localUrl, "时长:" + duration + "ms");

      //   //   this.upload(blob); //把blob文件上传到服务器

      //     this.rec.close(); //关闭录音，释放录音资源，当然可以不释放，后面可以连续调用start
      //     this.rec = null;
      //   },
      //   (err) => {
      //     console.error("结束录音出错：" + err);
      //     this.rec.close(); //关闭录音，释放录音资源，当然可以不释放，后面可以连续调用start
      //     this.rec = null;
      //   }
      // );
    },
    recPlay() {
      //本地播放录音试听，可以直接用URL把blob转换成本地播放地址，用audio进行播放
      var localUrl = URL.createObjectURL(this.recBlob);
      var audio = document.createElement("audio");
      audio.controls = true;
      document.body.appendChild(audio);
      audio.src = localUrl;
      audio.play(); //这样就能播放了

      //注意不用了时需要revokeObjectURL，否则霸占内存
      setTimeout(function () {
        URL.revokeObjectURL(audio.src);
      }, 5000);
    },
    recClose() {
      if (this.rec) this.rec.close();
      if (this.asr) this.asr.stop();
      this.rec = null;
      this.asr = null;
      this.recording = false;
    },
  },
};
