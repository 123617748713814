//讯飞实时语音转写 https://www.xfyun.cn/doc/asr/rtasr/API.html
(function(){
window.XfAsr=function(set){
	this.set=set;
	
	this.buffers=[];
	this.buffersIdx=0;
	this.inputErrMsg="";
	
	this.isStart=0;
	this.textChunks=[];
	this.chunkText="";
	this.endText="";
};
var Tag="[XfAsr]";
var NOOP=function(){};
XfAsr.prototype={
	start:function(True,False){
		True=True||NOOP; False=False||NOOP;
		var This=this;
		
		var failCall=function(err){
			clearTimeout(This.wsTimeout);
			if(!This.inputErrMsg){
				This.inputErrMsg=err;
			}
			if(This.waitEndFn){
				This.waitEndFn();
			}
			if(!This.isStart){
				console.error(Tag+Date.now()+" start失败："+err);
				This.isStart=-1;
				False(err);
			}
		}
		
		var data=This.set.authData;
		var hasEmpty=false;
		var checkEmpty=function(k){
			if(!hasEmpty && !data[k]){
				failCall("语音识别鉴权失败：接口返回数据缺失"+k+"参数");
				hasEmpty=true;
			}
			return checkEmpty;
		}
		checkEmpty("appID")("path")("signa")("date")("host");
		if(hasEmpty)return;
		if(data.path!="/v1/ws"){
			failCall("语音识别鉴权失败：不支持的path "+data.path+"，可能需升级语音识别代码");
			return;
		}
		
		//https://www.xfyun.cn/doc/asr/voicedictation/API.html
		var wss="wss://"+data.host+data.path
			+"?appid="+encodeURIComponent(data.appID)
			+"&ts="+encodeURIComponent(data.date)
			+"&signa="+encodeURIComponent(data.signa);
		
		console.log(Tag+Date.now()+" wss正在连接...", wss);
		var ws=new WebSocket(wss);
		This.wsTimeout=setTimeout(function(){
			if(This.ws){
				failCall("语音识别出错：网络请求超时");
			}
		},5000);
		
		ws.onclose=function(){
			console.log(Tag+"wss连接已关闭");
			if(This.ws){
				failCall("语音识别出错：连接被关闭");
			}
		};
		ws.onopen=function(){
			console.log(Tag+Date.now()+" wss onopen");
			if(!This.isStart){
				clearTimeout(This.wsTimeout);
				This.isStart=1;
				True();
			}
		};
		ws.onerror=function(e){
			console.error(Tag+"wss onerror",e);
			var msg="语音识别出错：网络连接错误";
			if(e.message)msg+="，"+e.message;
			// failCall(msg);
		};
		ws.onmessage=function(e){
			var data={};
			try{
				data=JSON.parse(e.data);
			}catch(e){}
			if(data.code!=="0"&&data.code!==0){
				failCall("语音识别出错["+data.code+"]："+data.desc);
				return;
			}
			var data0=data;
			data=data0.data&&JSON.parse(data0.data);
			if(!data){
				console.log(Tag+"wss onmessage 无数据",  data0);
				return;
			}
			
			var chunks=This.textChunks;
			
			var res=((data.cn||{}).st||{}).rt;
			var chunk={txt:"",ok:0};
			if(res){
				if(data.cn.st.type=="0")chunk.ok=1;
				for(var i=0;i<res.length;i++){
					var ws=res[i].ws;
					for(var j=0;j<ws.length;j++){
						var cw=ws[j].cw;
						for(var j2=0;j2<cw.length;j2++){
							chunk.txt+=cw[j2].w;
						}
					}
				}
				if(chunk.txt){
					var last=chunks[chunks.length-1];
					if(last&&!last.ok)chunks.pop();
					chunks.push(chunk);
					
					var str=This.chunkText;
					if(chunk.ok){
						This.chunkText+=chunk.txt;
					}
					str+=chunk.txt;
					This.set.onText(str);
				}
			}
			console.log(Tag+"wss onmessage",  chunk.txt,data,data0);
			
			if(data.ls||data.isEnd){
				var txt=This.chunkText+(chunk.ok?"":chunk.txt);
				This.endText=txt;
				console.log(Tag+Date.now()+" 识别成功",chunks,txt);
				if(This.waitEndFn)This.waitEndFn();
			}
		};
		This.ws=ws;
		
		This.writeInt=setInterval(function(){
			This.input();
		},50);
	}
	,stop:function(True,False){
		True=True||NOOP; False=False||NOOP;
		var This=this;
		
		var clear=function(){
			clearInterval(This.writeInt);
			var ws=This.ws;This.ws=null;
			if(ws)ws.close();
		};
		if(This.inputErrMsg){
			clear();
			False(This.inputErrMsg);
			return;
		}
		This.waitEndFn=function(){
			This.waitEndFn=null;
			clear();
			
			if(This.inputErrMsg){
				False(This.inputErrMsg);
			}else if(!This.endText){
				// False("语音识别失败：未识别到内容");
			}else{
				True(This.endText);
			}
		};
		if(This.endText || This.buffers.length==0){
			This.waitEndFn();
		}
	}
	
	
	,input:function(pcm){
		var This=this,buffers=This.buffers;
		if(This.inputErrMsg){
			var fn=This.set.onError;
			if(!This.onIeIsCall && fn){
				This.onIeIsCall=1;
				fn(This.inputErrMsg);
			}
			return;
		}
		
		if(pcm)buffers.push(pcm);
		if(!This.isStart){
			return;
		}
		
		pcm=buffers[This.buffersIdx]||[];
		var pcmDur=pcm.length/16000*1000;
		var lastSendTime=This.lastSendTime||0;
		//控制发送速率
		if(Date.now()-lastSendTime<pcmDur*3/4){
			return;
		}
		var data=null;
		if(This.buffersIdx == buffers.length-1){
			//最后一帧，等待结束信号
			if(!This.waitEndFn){
				return;
			}
			data='{"end": true}';
			if(This.buffersIdx==0){
				// This.inputErrMsg="语音识别失败：录音太短";
				This.waitEndFn();
				return;
			}
		}
		if(!data){
			//数据帧
			if(!pcm.length)return;
			data=pcm.buffer;
		}
		
		This.buffersIdx++;
		This.lastSendTime=Date.now();
		This.ws.send(data);
	}
};

})();