/*
 * @Description: 
 * @Version: 
 * @Autor: ping
 * @Date: 2023-08-13 20:14:41
 * @LastEditors: ping
 * @LastEditTime: 2023-08-13 20:14:52
 */
export default {
  data() {
    return {
      canClick: true
    };
  },
  methods: {
    handleClickWithLimit(callback) {
      if (this.canClick) {
        callback();
        this.canClick = false;

        setTimeout(() => {
          this.canClick = true;
        }, 2000);
      }
    }
  }
};