/**
 * @                       _oo0oo_
 * @                      o8888888o
 * @                      88" . "88
 * @                      (| -_- |)
 * @                      0\  =  /0
 * @                    ___/`---'\___
 * @                  .' \\|     |// '.
 * @                 / \\|||  :  |||// \
 * @                / _||||| -:- |||||- \
 * @               |   | \\\  - /// |   |
 * @               | \_|  ''\---/''  |_/ |
 * @               \  .-\__  '-'  ___/-. /
 * @             ___'. .'  /--.--\  `. .'___
 * @          ."" '<  `.___\_<|>_/___.' >' "".
 * @         | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 * @         \  \ `_.   \_ __\ /__ _/   .-` /  /
 * @     =====`-.____`.___ \_____/___.-`___.-'=====
 * @                       `=---='
 * @
 * @
 * @     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * @
 * @           佛祖保佑     永不宕机     永无BUG
 * @
 * @       佛曰:  
 * @               写字楼里写字间，写字间里程序员；  
 * @               程序人员写程序，又拿程序换酒钱。  
 * @               酒醒只在网上坐，酒醉还来网下眠；  
 * @               酒醉酒醒日复日，网上网下年复年。  
 * @               但愿老死电脑间，不愿鞠躬老板前；  
 * @               奔驰宝马贵者趣，公交自行程序员。  
 * @               别人笑我忒疯癫，我笑自己命太贱；  
 * @               不见满街漂亮妹，哪个归得程序员？
 * @
 * @Author: wangping 1683997498@qq.com
 * @Date: 2023-12-20 08:44:41
 * @LastEditors: wangping 1683997498@qq.com
 * @LastEditTime: 2024-04-03 14:26:16
 * @FilePath: \twzc005-1\src\main.js
 * @Description: 
 * @
 * @版权所有 (c) 2024 广东天网智城科技有限公司, 保留所有权利。 
 */



import Vue from 'vue'
import App from './app3.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import audio from 'vue-mobile-audio'

Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  // audio,
  render: h => h(App)
}).$mount('#app')

